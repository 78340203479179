@import "antd/dist/antd.css";

.App {
  font-family: "Poppins", sans-serif !important;
  margin: 0 auto;
  padding: 0;
  background: linear-gradient(136deg, #181818 5.74%, #090909 64.72%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  scroll-behavior: smooth;
  height: 100%;
}

* {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  line-height: normal;
}

body {
  scrollbar-width: thin;
  scrollbar-color: rgba(52, 52, 52, 0.22) rgba(32, 32, 32, 0.63);
  height: 100%;
}

@media (min-width: 768px) {
  ::-webkit-scrollbar-track {
    background-color: rgba(32, 32, 32, 0.63);
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(52, 52, 52, 0.22);
    border-radius: 8px;
  }

  body::-webkit-scrollbar-thumb {
    background-color: rgba(52, 52, 52, 0.22);
    border-radius: 8px;
  }

  body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(52, 52, 52, 0.22);
  }
}

.body-container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  font-family: "Poppins", sans-serif !important;
}

p {
  margin-bottom: 0;
  font-family: "Poppins", sans-serif !important;
}

.custom-font-200 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 200;
}

.custom-font-300 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
}

.custom-font-400 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}
.custom-font-500 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
}

.custom-font-600 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
}
.custom-font-700 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
}

.center-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gradient-text {
  font-family: "Poppins", sans-serif !important;
  background: var(--green, linear-gradient(133deg, #4afc2d 0%, #1ea707 100%));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.gradient-text-red {
  font-family: "Poppins", sans-serif !important;
  background: var(--Red, linear-gradient(180deg, #fd0909 0%, rgba(240, 12, 12, 0.53) 100%));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

/* map */

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  z-index: 0;
}

.topbar-map-custom-z {
  z-index: 99999 !important;
}

.custom-z {
  z-index: 999 !important;
}

.map-wrapper-custom-width {
  max-height: calc(100vh - 20px);
  border-radius: 20px;
}

.map-wrapper-bg {
  background: linear-gradient(143deg, #181818 4.32%, #090909 62.85%);
}

.map-wrapper-bg .ant-spin-dot-item {
  background-color: white; /* Set the background color to white */
}

.map-search {
  width: 400px;
  height: 55px;
  border-radius: 18.721px;
  border: 0.852px solid rgba(78, 78, 78, 0.36);
  background: rgba(0, 0, 0, 0.63) !important;
  backdrop-filter: blur(2.5px);
  color: rgba(255, 255, 255, 0.61);
  font-size: 16px;
}

.custom-map-search .ant-input-affix-wrapper > .ant-input:not(textarea) {
  background: transparent !important;
  color: white !important;
  font-size: 16px !important;
}

.map-search .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 0.852px solid rgba(78, 78, 78, 0.36) !important;
}

.map-search:hover {
  border: 0.852px solid rgba(78, 78, 78, 0.36) !important;
  box-shadow: 0 0 0 2px rgba(78, 78, 78, 0.36) !important;
}

.map-search:focus {
  border: 0.852px solid rgba(78, 78, 78, 0.36) !important;
  box-shadow: 0 0 0 2px rgba(78, 78, 78, 0.36) !important;
}

.map-search:active {
  border: 0.852px solid rgba(78, 78, 78, 0.36) !important;
  box-shadow: 0 0 0 2px rgba(78, 78, 78, 0.36) !important;
}

.map-search-list {
  border-radius: 18.721px;
  background: rgba(0, 0, 0) !important;
}

.ant-list-split .ant-list-item {
  border: 1px solid transparent !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  display: none;
}

.custom-hotspot-search:focus {
  border: 1px solid #1ea707;
}
.map-icons-wrapper {
  background: rgba(0, 0, 0, 0.63);
  backdrop-filter: blur(2.760014533996582px);
  width: 52.992px;
  height: 52.992px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

/* login */

.active-login-sidebar {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: black;
  transition: all 0.5s;
}

.not-active-login-sidebar {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: black;
  transition: all 0.5s;
}

.custom-input {
  font-size: 16px;
  color: white;
  background-color: rgba(34, 34, 34, 0.35);
  padding: 20px;
  border-radius: 20px;
  border: 1px solid rgba(34, 34, 34, 0.35);
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.custom-input:hover {
  border-color: white !important;
  box-shadow: 0 0 0 1px white !important;
}

.custom-input:focus {
  border-color: #1ea707 !important;
  box-shadow: 0 0 0 1px #1ea707 !important;
}

.custom-input:active {
  border-color: #1ea707 !important;
}

.custom-input::placeholder {
  color: #494949;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.custom-border {
  border: 0.001px solid #4f7942;
}

.login-navbar {
  border-radius: 18.721px;
  border: 1px solid rgba(78, 78, 78, 0.36);
  background: rgba(32, 32, 32, 0.63);
  backdrop-filter: blur(2.5px);
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  padding-left: 30px;
}

.card-width {
  width: 430px;
}

.custom-login-border {
  border: 0.2px solid var(--Green, #4afc2d);
}

@media screen and (max-width: 767px) {
  .placement {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .card-width {
    width: 100%;
  }
  .custom-input {
    padding: 18px;
  }
}

@media screen and (min-width: 1720px) {
  .card-width {
    width: 550px;
  }
}

/* signup */

.ant-checkbox-inner {
  background-color: transparent;
  border: 1px solid white !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
  border-color: white !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: white !important;
}

.ant-checkbox-checked::after {
  border: 1px solid white !important;
}

.ant-checkbox-checked::before {
  border: 1px solid white !important;
}

.ant-checkbox-checked:focus {
  border-color: white !important;
  background-color: transparent;
}

.ant-checkbox-checked:active {
  border-color: white !important;
  background-color: transparent;
}

.custom-signup-border {
  border: 0.2px solid var(--GOld, #f0d59e);
}

/* layout */

.custom-sidebar-style {
  border-radius: 18.721px;
  border: 0.9px solid rgba(78, 78, 78, 0.36);
  background: rgba(32, 32, 32, 0.63);
  z-index: 1000;
}

.custom-sidebar-style-rtk {
  /*  */
  border-radius: 18.721px;
  border: 0.852px solid rgba(78, 78, 78, 0.36);
  background: rgba(32, 32, 32, 0.07);
  z-index: 1000;
}

.mobile-custom-topbar-style {
  /* border-radius: 18.721px; */
  background: rgba(20, 20, 20, 0.62);
  backdrop-filter: blur(2.5px);
  min-height: 76px;
  max-height: 76px;
  /* z-index: 1000000000000000; */
  z-index: 205;
  /* z-index: 1000; */
  /* z-index: 204; */
}
.sidebar-custom-z {
  z-index:3000000000000000 ;
}
.mobile-custom-topbar-style-z {
  background: rgba(20, 20, 20, 0.62);
  backdrop-filter: blur(2.5px);
  min-height: 76px;
  max-height: 76px;
  /* z-index: 50; */
  z-index: 1000000000000000;
}

.bottom-menu-custom-z {
  /* z-index: 20000000000; */
  /* z-index: 28; */
  z-index: 210;
}
.zz {
  position: relative;
  /* z-index: 201; */
}
.leaflet-pane svg {
  z-index: 100;
}

.custom-topbar-style {
  border-radius: 18.721px;
  border: 0.9px solid rgba(78, 78, 78, 0.36);
  background: rgba(32, 32, 32, 0.63);
  backdrop-filter: blur(2.5px);
}

.custom-line {
  background: rgba(255, 255, 255, 0.32);
}

.user-custom-button {
  border-radius: 11px;
  background: #343434;
  border: 1px solid transparent;
}

.user-custom-button:hover {
  border-radius: 11px;
  background: transparent;
  border: 1px solid #4afc2d;
}

.sidebar-user-custom-button {
  border-radius: 14.603px;
  background: #343434;
}

.info-navbar {
  border-radius: 15px;
  background: rgba(37, 37, 37, 0.4);
}

.custom-mobile-menu {
  border: 0.852px solid rgba(78, 78, 78, 0.36);
  background: rgba(32, 32, 32, 0.84);
  backdrop-filter: blur(5px);
  min-height: 87px;
  max-height: 87px;
}

.wallet-mobile-sidebar-bg {
  border: none;
  background: transparent;
  backdrop-filter: none;
}

.custom-setting-vh {
  height: calc(100vh - 160px);
}

@media screen and (max-width: 768px) {
  .custom-sidebar-style {
    background: linear-gradient(136deg, #181818 5.74%, #090909 64.72%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .map-wrapper-custom-width {
    max-height: calc(100vh - 300px);
    border-radius: 20px;
  }
  .wallet-mobile-sidebar-bg {
    border: 0.852px solid rgba(78, 78, 78, 0.36);
    background: rgba(32, 32, 32, 0.91);
    backdrop-filter: blur(5px);
  }
}

/* password change */

.password-input {
  margin-top: 16px;
  font-size: 16px;
  color: white;
  padding: 20px 25px 20px 25px;
  border-radius: 20px;
  border: 1px solid #414141;
  background: rgba(34, 34, 34, 0.35);
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.password-input:hover {
  border-color: white !important;
  box-shadow: 0 0 0 1px white !important;
}

.password-input:focus {
  border-color: #1ea707 !important;
  box-shadow: 0 0 0 1px #1ea707 !important;
}

.password-input:active {
  border-color: #1ea707 !important;
}

.password-input::placeholder {
  color: rgba(255, 255, 255, 0.58);
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 16px;
}

.cancel-text {
  background-color: #292929;
}

@media screen and (max-width: 768px) {
  .password-input {
    font-size: 16px;
    padding: 15px 20px;
    border: 1px solid rgba(34, 34, 34, 0.35);
    border-radius: 15px;
    background: rgba(37, 37, 37, 0.4);
  }
  .password-input:hover {
    border-color: rgba(34, 34, 34, 0.35) !important;
    box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.35) !important;
  }

  .password-input:focus {
    border-color: rgba(34, 34, 34, 0.35) !important;
    box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.35) !important;
  }

  .password-input:active {
    border-color: rgba(34, 34, 34, 0.35) !important;
  }

  .password-input::placeholder {
    font-family: "Poppins", sans-serif !important;
    font-weight: 300;
    font-size: 16px;
  }
  .custom-login-border {
    border: 0.2px solid var(--GOld, #f0d59e);
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .password-input::placeholder {
    font-size: 15px;
  }
}

/* info */

.custom-border-line {
  background: #2c2c2c;
}

.image-circle {
  background-color: rgba(217, 217, 217, 0.09);
}

/* custom-mobile-tab */

.custom-mobile-tab {
  background-color: rgba(37, 37, 37, 0.4);
}

/* use RTK */

.mobile-rtk-create-account {
  border: 1px solid transparent;
  background: transparent;
  backdrop-filter: blur(5px);
}

.mobile-rtk-create-account {
  border: none;
}
.rtk-note {
  color: rgba(255, 255, 255, 0.57);
}

.rtk-account-section {
  background: linear-gradient(143deg, #181818 4.32%, #090909 62.85%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 18.721px;
  border: 0.852px solid rgba(66, 66, 66, 0.63);
}

.rtk-connectivity-section {
  border-radius: 7px;
  background: rgba(7, 100, 167, 0.18);
}

.rtk-mountpoibt-section {
  background: linear-gradient(143deg, #181818 4.32%, #090909 62.85%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 18.721px;
  border: 0.852px solid rgba(66, 66, 66, 0.63);
}

.rtk-log-data-section {
  background: linear-gradient(143deg, #181818 4.32%, #090909 62.85%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 18.721px;
  border: 0.852px solid rgba(66, 66, 66, 0.63);
}

.rtk-main-sction-icon {
  border-radius: 11px;
  background: rgba(52, 52, 52, 0.4);
  padding: 8px;
}

.rtk-mobile-add-button {
  border-radius: 8.022px;
  border: 0.802px dashed rgba(255, 255, 255, 0.07);
  background: rgba(52, 52, 52, 0.22);
}

.ntrip-account-list-table tr td {
  color: rgba(255, 255, 255, 0.39);
  padding: 15px 20px;
  text-align: left;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  white-space: nowrap;
}

.ntrip-account-list-table tbody tr td {
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  color: rgba(255, 255, 255, 1);
}

.ntrip-account-list-table tbody tr:nth-child(odd) {
  border-radius: 7px;
  background: rgba(52, 52, 52, 0.22);
}

.ntrip-account-list-table tbody tr:nth-child(even) {
  background: transparent;
}

/* .ntrip-account-list-table tbody tr:nth-child(odd) td {
  border-radius: 7px 0 0 7px;
}

.ntrip-account-list-table tbody tr:nth-child(even) td {
  border-radius: 7px 0 0 7px;
} */

.configure-list-table thead tr {
  border-radius: 17px;
  border: 1px solid #249cf3 !important;
  background: rgba(62, 109, 215, 0.19);
}
.configure-list-table thead tr td {
  padding-top: 4px;
  padding-bottom: 4px;
  border-top: 1px solid #249cf3;
  border-bottom: 1px solid #249cf3;
  padding-left: 8px;
}

.configure-list-table thead tr td:nth-child(1) {
  border-radius: 17px 0 0 17px;
  border-left: 1px solid #249cf3;
}

.configure-list-table thead tr td:last-child {
  border-radius: 0 17px 17px 0;
  border-right: 1px solid #249cf3;
}

.configure-list-table tbody tr td {
  padding: 15px 0px;
  text-align: left;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  white-space: nowrap;
}

.configure-list-table tbody tr td {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
}

.configure-list-table tbody tr:nth-child(2) {
  background: rgba(62, 109, 215, 0.19) !important;
}

.configure-list-table tbody tr:nth-child(2) td {
  border-top: 1px solid #249cf3;
  border-bottom: 1px solid #249cf3;
}

.configure-list-table tbody tr:nth-child(2) td:nth-child(1) {
  border-left: 1px solid #249cf3;
}

.configure-list-table tbody tr:nth-child(2) td:last-child {
  border-right: 1px solid #249cf3;
}

.configure-list-table tbody tr td:nth-child(1) {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}

.configure-list-table tbody tr td {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  background: rgba(217, 217, 217, 0.02);
}

.configure-list-table tbody tr td:nth-child(1) {
  border-left: 1px solid rgba(255, 255, 255, 0.07);
}

.configure-list-table tbody tr td:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.07);
}

.configure-list-table tbody tr td:nth-child(1) {
  border-radius: 17px 0 0 17px;
}

.configure-list-table tbody tr td:last-child {
  border-radius: 0 17px 17px 0;
}

.table-container {
  overflow-x: auto;
}

.ntrip-account-list-search {
  border-radius: 7px;
  background: rgba(52, 52, 52, 0.22);
  color: rgba(255, 255, 255, 0.39);
}

.custom-ntrip-list-search-input {
  background: transparent !important;
  color: rgba(255, 255, 255, 0.39);
  border-color: transparent !important;
}

.custom-ntrip-list-search-input::placeholder {
  color: rgba(255, 255, 255, 0.39);
}

.custom-ntrip-list-search-input:focus {
  border-color: rgba(52, 52, 52, 0.22) !important;
  box-shadow: 0 0 0 1px rgba(52, 52, 52, 0.22) !important;
}

.custom-ntrip-list-search-input:hover {
  border-color: rgba(52, 52, 52, 0.22) !important;
  box-shadow: 0 0 0 1px rgba(52, 52, 52, 0.22) !important;
}

.custom-ntrip-list-search-input:active {
  border-color: rgba(52, 52, 52, 0.22) !important;
  box-shadow: 0 0 0 1px rgba(52, 52, 52, 0.22) !important;
}

.mountpoints-list-search {
  border-radius: 13.557px;
  border: 0.617px solid rgba(78, 78, 78, 0.36);
  background: rgba(0, 0, 0, 0.63);
  backdrop-filter: blur(1.8103446960449219px);
  color: rgba(255, 255, 255, 0.61);
}

.mountpoints-list-search-input {
  background: transparent !important;
  color: rgba(255, 255, 255, 0.61);
  border-color: transparent !important;
}

.mountpoints-list-search-input::placeholder {
  color: rgba(255, 255, 255, 0.61);
}

.mountpoints-list-search-input:focus {
  border-color: transparent !important;
  box-shadow: 0 0 0 1px transparent !important;
}

.mountpoints-list-search-input:hover {
  border-color: transparent !important;
  box-shadow: 0 0 0 1px transparent !important;
}

.mountpoints-list-search-input:active {
  border-color: transparent !important;
  box-shadow: 0 0 0 1px transparent !important;
}

.rtk-credits-div {
  border-radius: 7px;
  background: #202020;
}
.rtk-usage-div {
  border-radius: 0 7px 7px 0;
  background: #202020;
}

.sidebar-custom-height {
  height: auto !important;
}

@media screen and (max-width: 768px) {
  .ntrip-account-list-table-header {
    padding: 15px 30px;
  }
  .ntrip-account-list-table tr:nth-child(even) td {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ntrip-account-list-table tr:nth-child(odd) td {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .rtk-account-section {
    border-radius: 7px;
    border: 0.5px solid #454545;
    background: #202020;
  }

  .rtk-mountpoibt-section {
    border-radius: 7px;
    border: 0.5px solid #454545;
    background: #202020;
  }
  .rtk-log-data-section {
    border-radius: 7px;
    border: 0.5px solid #454545;
    background: #202020;
  }
  .mobile-rtk-create-account {
    border: 1px solid rgba(78, 78, 78, 0.36);
    background: rgba(32, 32, 32, 0.84);
    backdrop-filter: blur(5px);
  }

  .mobile-rtk-create-account {
    border: none;
  }
  .main-mobile-sidebar {
    border: 0.852px solid rgba(78, 78, 78, 0.36);
    background: rgba(32, 32, 32, 0.91);
    backdrop-filter: blur(5px);
    min-height: calc(100vh - 165px);
  }

  .mobile-sidebar-hotspot-bg {
    border: 0.852px solid rgba(78, 78, 78, 0.36);
    background: rgba(32, 32, 32, 0.91);
    backdrop-filter: blur(5px);
    min-height: calc(100vh - 165px);
  }
}

.sidebar-account-div {
  border-radius: 12.94px;
  background: rgba(37, 37, 37, 0.4);
}

@media not all and (max-width: 768px) {
  .rtk-mountpoint-section {
    padding-top: 20px;
    padding-bottom: 20px;
    background: linear-gradient(143deg, #181818 4.32%, #090909 62.85%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 18.721px;
    border: 0.852px solid rgba(66, 66, 66, 0.63);
  }
}

@media screen and (max-width: 768px) {
  .rtk-mountpoint-mobile-section {
    border-radius: 7px;
    border: 0.5px solid #454545;
    background: #202020;
  }
}

@media screen and (max-width: 425px) {
  .rtk-custom-vh {
    min-height: calc(100vh - 258px);
  }

  .hotspot-custom-vh {
    min-height: calc(100vh - 378px);
  }

  .wallet-custom-vh {
    min-height: calc(100vh - 378px);
  }

  .rtk-hotspot-custom-vh {
    min-height: calc(100vh - 188px);
  }

  .rtk-main-sction-icon {
    padding: 8px;
  }
  .main-rtk-custom-vh {
    max-height: calc(100vh - 295px) !important;
  }
  .sidebar-custom-vh {
    min-height: calc(100vh - 163px) !important;
    max-height: calc(100vh - 163px) !important;
  }
  /* .sidebar-custom-height {
    height: calc(100vh - 87px) !important;
  } */
}

@media screen and (max-width: 768px) and (min-width: 426px) {
  .rtk-custom-vh {
    min-height: calc(100vh - 300px);
  }
  .hotspot-custom-vh {
    min-height: calc(100vh - 500px);
  }
  .wallet-custom-vh {
    min-height: calc(100vh - 500px);
  }
  .rtk-hotspot-custom-vh {
    min-height: calc(100vh - 188px);
  }

  .main-rtk-custom-vh {
    max-height: calc(100vh - 295px) !important;
  }
  .sidebar-custom-vh {
    min-height: calc(100vh - 163px) !important;
  }
  /* .sidebar-custom-height {
    height: calc(100vh - 87px) !important;
  } */
}

/* modal */

.custom-modal-style {
  border-radius: 29px;
  border: 1px solid rgba(78, 78, 78, 0.36);
  background: #1b1b1b;
}

.modal-custom-input {
  border-radius: 20px;
  background: rgba(34, 34, 34, 0.7);
  border-color: rgba(34, 34, 34, 0.7);
  padding: 20px 25px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  color: white;
}

.modal-custom-input::placeholder {
  color: #9a9a9a;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
}

.modal-custom-input:hover {
  box-shadow: 0 0 0 1px white !important;
  border-color: white !important;
}

.modal-custom-input:focus {
  box-shadow: 0 0 0 1px #1ea707 !important;
  border-color: #1ea707 !important;
}

.modal-custom-input:active {
  border-color: #1ea707 !important;
}

@media screen and (max-width: 768px) {
  .modal-custom-input {
    border-radius: 20px;
    padding: 12px 18px;
  }

  .modal-custom-input::placeholder {
    font-size: 14px;
  }
}

/* hotspots */

.hotspot-logo-circle {
  background-color: rgba(217, 217, 217, 0.09);
}

.connect-hotspot-button {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #484848 50%, transparent 50%), linear-gradient(90deg, #484848 50%, transparent 50%),
    linear-gradient(0deg, #484848 50%, transparent 50%), linear-gradient(0deg, #484848 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 30px 1px, 30px 1px, 1px 30px, 1px 30px;
  background-position: 0px 0px, 100% 100%, 0px 100%, 100% 0px;
  animation: border-dance 25s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: 0px 0px, 100% 100%, 0px 100%, 100% 0px;
  }
  100% {
    background-position: 100% 0px, 0px 100%, 0px 0px, 100% 100%;
  }
}

.hotspot-divider .ant-divider-horizontal.ant-divider-with-text::before {
  border-top: 1px solid #545454;
  border-top-color: #545454;
}

.hotspot-divider .ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #545454;
  border-top-color: #545454;
}

.mobile-hotspot-stats-card {
  border-radius: 6.314px;
  background: rgba(7, 100, 167, 0.18);
  padding: 10px 15px 5px 15px;
}

.mobile-hotspot-stats-card span {
  color: rgba(255, 255, 255, 0.56);
  font-size: 12px;
}

.mobile-hotspot-stats-card h1,
h2 {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  margin-bottom: 0;
}

.mobile-hotspot-stats-card p {
  color: rgba(255, 255, 255, 0.61);
  font-size: 12px;
}

.custom-connect-input {
  font-size: 16px;
  color: white;
  padding: 20px 25px;
  border-radius: 20px;
  background: rgba(34, 34, 34, 0.7);
  border: 1px solid rgba(34, 34, 34, 0.7);
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.custom-connect-input:hover {
  border-color: white !important;
  box-shadow: 0 0 0 1px white !important;
}

.custom-connect-input:focus {
  border-color: #1ea707 !important;
  box-shadow: 0 0 0 1px #1ea707 !important;
}

.custom-connect-input:active {
  border-color: #1ea707 !important;
}

.custom-connect-input::placeholder {
  color: #9a9a9a;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.custom-card-connect-server {
  border-radius: 29px;
  background: rgba(7, 100, 167, 0.18);
}

/* .custom-card-configure-server {
  border-radius: 29px;
  background: rgba(7, 100, 167, 0.18);
} */

.custom-card-configure-server {
  border-radius: 29px;
  background: transparent;
}

.setting-left-border {
  border-left: 1px solid #5a5a5a;
}

.setting-right-border {
  border-right: 1px solid transparent;
}

.configure-custom-switch .ant-switch-handle::before {
  border-radius: 4px;
  background: var(--green, linear-gradient(133deg, #4afc2d 0%, #1ea707 100%));
}

.configure-custom-switch .ant-switch {
  color: #000000 !important;
  background-color: #000000 !important;
  border-radius: 6px;
}

.custom-hotspot-search {
  border-radius: 18.721px;
  border: 0.852px solid rgba(78, 78, 78, 0.36);
  background: rgba(0, 0, 0, 0.63);
  backdrop-filter: blur(2.5px);
}

.custom-hotspot-search-input {
  background: transparent !important;
  color: rgba(255, 255, 255, 0.61);
  box-shadow: none !important;
  border-radius: none;
  border: 0.617px solid transparent !important;
  /* width: 400px; */
  /* height: 60px; */
}

.custom-hotspot-search-input::placeholder {
  color: rgba(255, 255, 255, 0.61);
}

.custom-hotspot-search-input:focus {
  border-color: rgba(0, 0, 0, 0.63) !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.63) !important;
}

.custom-hotspot-search-input:hover {
  border-color: rgba(0, 0, 0, 0.63) !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.63) !important;
}

.custom-hotspot-search-input:active {
  border-color: rgba(0, 0, 0, 0.63) !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.63) !important;
}

.custom-hotspot-search:hover {
  border: 1px solid white;
}

.custom-hotspot-search:focus {
  border: 1px solid #1ea707;
}

.hotspot-custom-grid {
  border-radius: 24px;
  background: rgba(32, 32, 32, 0.49);
}

.grid-item-text {
  border-bottom: 1px solid rgba(116, 116, 116, 0.14);
}

.custom-base-action {
  border-radius: 1px 2px 24px 24px;
  background: rgba(32, 32, 32, 0.49);
}

.sidebar-chart-div {
  border-radius: 19px;
  border: 0.5px solid #282828;
  background: rgba(7, 100, 167, 0.18);
}

.country-graph-section {
  border-radius: 18.656px;
  border: 0.491px solid #282828;
  background: #202020;
}

.uptime-graph-section {
  border-radius: 18.656px;
  border: 0.491px solid #282828;
  background: #151515;
}

.uptime-graph-sections {
  border-radius: 18.656px;
  border: 0.491px solid #282828;
  background: #151515;
  width: 100%;
}

.location-section {
  border-radius: 7px;
  background: rgba(7, 100, 167, 0.18);
}

.gps-graph-div {
  border-radius: 18.656px;
  background: rgba(32, 32, 32, 0.52);
}

.satellite-detail {
  border-radius: 18.656px;
  background: rgba(32, 32, 32, 0.52);
}

.config-modal-div {
  border-radius: 17px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  background: rgba(217, 217, 217, 0.02);
}

.baseview-sidebar-mobile-bg {
  border: none;
  background: transparent;
  backdrop-filter: none;
}

@media screen and (max-width: 768px) and (min-width: 426px) {
  .main-hotspot-custom-vh {
    max-height: calc(100vh - 340px) !important;
  }
  .sidebar-chart-div {
    border-radius: 7px;
    border: 0.5px solid #454545;
    background: #202020;
  }
  .base-custom-vh {
    max-height: calc(100vh - 340px);
  }
  .custom-modal-style {
    border-radius: 25.584px;
    border: 0.751px solid rgba(78, 78, 78, 0.36);
    background: rgba(44, 44, 44, 0.74);
    backdrop-filter: blur(4.5px);
  }
  .config-modal-div {
    border-radius: 8.235px;
    border: 0.484px solid rgba(255, 255, 255, 0.07);
    background: rgba(217, 217, 217, 0.02);
  }
  .baseview-sidebar-mobile-bg {
    border: 0.852px solid rgba(78, 78, 78, 0.36);
    background: rgba(32, 32, 32, 0.91);
    backdrop-filter: blur(5px);
  }
  .custom-card-configure-server {
    border-radius: none;
    background: transparent;
  }

  .w3m-container {
    margin-block: auto !important;
    width: 300px !important;
    margin-bottom: 50% !important;
    position: absolute;
    top: 0;
    left: 0;
  }
  .w3m-overlay {
    margin-bottom: 50% !important;
  }
}

@media screen and (max-width: 425px) {
  .main-hotspot-custom-vh {
    max-height: calc(100vh - 340px) !important;
  }
  .sidebar-chart-div {
    border-radius: 7px;
    border: 0.5px solid #454545;
    background: #202020;
  }
  .base-custom-vh {
    max-height: calc(100vh - 338px);
  }
  .custom-modal-style {
    border-radius: 25.584px;
    border: 0.751px solid rgba(78, 78, 78, 0.36);
    background: rgba(44, 44, 44, 0.74);
    backdrop-filter: blur(4.5px);
  }
  .config-modal-div {
    border-radius: 8.235px;
    border: 0.484px solid rgba(255, 255, 255, 0.07);
    background: rgba(217, 217, 217, 0.02);
  }
  .baseview-sidebar-mobile-bg {
    border: 0.852px solid rgba(78, 78, 78, 0.36);
    background: rgba(32, 32, 32, 0.91);
    backdrop-filter: blur(5px);
  }
  .custom-card-configure-server {
    border-radius: none;
    background: transparent;
  }
  .w3m-container {
    margin-block: auto !important;
    width: 300px !important;
    margin-bottom: 50% !important;
    position: absolute;
    top: 0;
    left: 0;
  }
  .w3m-overlay {
    margin-bottom: 50% !important;
  }
}

@media screen and (max-width: 320px) {
  .uptime-graph-sections {
    width: 300px;
  }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
  .uptime-graph-sections {
    width: 350px;
  }
}

@media screen and (min-width: 376px) and (max-width: 425px) {
  .uptime-graph-sections {
    width: 400px;
  }
}

.hotspot-list-table {
  border-radius: 18.656px;
  background: rgba(32, 32, 32, 0.52);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.hotspot-list-table thead tr td {
  color: rgba(255, 255, 255, 0.39);
  padding: 20px 20px;
  text-align: left;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  white-space: nowrap;
}

.hotspot-list-table tbody tr td {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.hotspot-list-table tbody tr:nth-child(odd) {
  border-radius: 7px;
  background: rgba(52, 52, 52, 0.22);
}

.hotspot-list-table tbody tr:nth-child(even) {
  background: transparent;
}

.hotspot-list-table tbody tr:nth-child(odd) td:first-child {
  border-radius: 7px 0 0 7px;
}

.hotspot-list-table tbody tr:nth-child(odd) td:last-child {
  border-radius: 0 7px 7px 0;
}

.table-container {
  overflow-x: auto;
}

@media screen and (max-width: 768px) {
  .buy-custom-radius {
    border-radius: 17px;
  }
  .custom-connect-input::placeholder {
    font-size: 14px;
  }
  .custom-connect-input {
    font-size: 14px;
    padding: 15px 20px;
    border-radius: 15px;
  }
  .hotspot-list-table {
    border-radius: 7px;
    border: 0.5px solid #454545;
    background: #202020;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .hotspot-list-table-header {
    padding: 15px 30px;
  }
  .hotspot-list-table tbody tr td {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 20px;
  }
  .hotspot-list-table thead tr td {
    padding: 10px 20px;
  }
}

/* use RINEX */

.rinex-minus-div {
  border-radius: 7px;
  background: rgba(74, 252, 45, 0.09);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rinex-plus-div {
  border-radius: 7px;
  background: rgba(148, 148, 148, 0.09);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rinex-success-div {
  border-radius: 7px;
  background: rgba(74, 252, 45, 0.09);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rinex-database-table thead tr {
  color: rgba(255, 255, 255, 0.39);
  text-align: left;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  white-space: nowrap;
  background-color: transparent;
}

.rinex-database-table tbody tr td {
  padding-top: 7px;
  padding-bottom: 7px;
}

.rinex-database-table tbody tr:nth-child(odd) {
  border-radius: 7px;
  background: none;
}

.rinex-database-table tbody tr:nth-child(odd) td:not(:last-child) {
  background: rgba(52, 52, 52, 0.22);
}

.rinex-database-table tbody tr:nth-child(odd) {
  border-radius: 7px;
  background: none;
}

.rinex-database-table tbody tr:nth-child(even) td:not(:last-child) {
  background: rgba(7, 100, 167, 0.18);
}

.rinex-database-table tbody tr td:last-child {
  background: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rinex-database-table tbody tr:nth-child(odd) td:first-child {
  border-radius: 7px 0 0 7px;
}

.rinex-database-table tbody tr:nth-child(odd) td:nth-last-child(2) {
  border-radius: 0 7px 7px 0;
}

.rinex-database-table tbody tr:nth-child(even) td:first-child {
  border-radius: 7px 0 0 7px;
}

.rinex-database-table tbody tr:nth-child(even) td:nth-last-child(2) {
  border-radius: 0 7px 7px 0;
}

.table-container {
  overflow-x: auto;
}

.rinex-database-table {
  border-collapse: separate;
  border-spacing: 8px 8px;
}

.rinex-custom-input {
  font-size: 14px;
  color: white;
  background: #202020;
  border-radius: 13px;
  border: 1px solid #202020;
  padding: 20px;
}

.rinex-custom-input:hover {
  border-color: white !important;
  box-shadow: 0 0 0 1px white !important;
}

.rinex-custom-input:focus {
  border-color: #1ea707 !important;
  box-shadow: 0 0 0 1px #1ea707 !important;
}

.rinex-custom-input:active {
  border-color: #1ea707 !important;
}

.rinex-custom-input::placeholder {
  color: rgba(255, 255, 255, 0.56);
  font-size: 14px;
}

.rinex-custom-dropdown {
  width: 100%;
  border-radius: 13px;
  background: #202020;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.hotspot-config-custom-dropdown {
  width: 100%;
  border-radius: 17px;
  background: rgba(217, 217, 217, 0.02);
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  color: white;
}

.rinex-custom-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
  background-color: rgba(32, 32, 32, 1);
  border: 1px solid rgba(32, 32, 32, 1);
  color: white !important;
  border-radius: 13px;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.hotspot-config-custom-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
  background-color: transparent;
  border: 1px solid transparent;
  color: white !important;
  border-radius: 17px;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.rinex-custom-dropdown
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: rgba(32, 32, 32, 1) !important;
  box-shadow: 0 0 0 2px rgba(32, 32, 32, 1) !important;
}

.hotspot-config-custom-dropdown
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: transparent !important;
  box-shadow: 0 0 0 2px transparent !important;
}

.rinex-custom-dropdown .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: white;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.hotspot-config-custom-dropdown .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: white;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

.rinex-custom-dropdown .ant-select-arrow {
  color: white;
  margin-right: 20px;
}

.hotspot-config-custom-dropdown .ant-select-arrow {
  color: white;
  margin-right: 20px;
}

.configure-select-size {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  color: white !important;
}

.rinex-download-section {
  border-radius: 18.721px;
  background: rgba(32, 32, 32, 0.63);
  backdrop-filter: blur(2.5px);
}

.sidebar-mobile-rinex {
  border: none;
  background: transparent;
  backdrop-filter: none;
}

.configure-bg {
  border-radius: 17px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  background: rgba(217, 217, 217, 0.02);
}

.configure-checkbox .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: transparent;
}

.configure-checkbox .ant-checkbox-inner {
  width: 16px; /* Adjust the size as needed */
  height: 16px; /* Adjust the size as needed */
}

.configure-switch .ant-switch {
  height: 20px;
  min-width: 40px;
}

/* Media query for small screens */

@media screen and (min-width: 768px) and (max-width: 991px) {
  .margin-custom-info {
    margin-top: 32px;
  }
}

@media (max-width: 768px) {
  .configure-checkbox .ant-checkbox-inner {
    width: 10px;
    height: 10px;
  }
  .configure-switch .ant-switch-handle {
    width: 15px;
    height: 15px;
  }
  .configure-switch .ant-switch {
    height: 18px;
    min-width: 34px;
  }
  .skyplot-checbox-width {
    width: 20%;
  }
}

@media screen and (max-width: 767px) {
  .rinex-custom-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
    font-size: 13px;
  }

  .hotspot-config-custom-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
    font-size: 13px;
  }
  .rinex-custom-input::placeholder {
    font-size: 13px;
  }
  .rinex-download-section {
    border-radius: none;
    background: rgba(20, 20, 20, 0.62);
    backdrop-filter: none;
  }
  .rinex-custom-dropdown .ant-select-arrow {
    margin-right: 10px;
  }
  .hotspot-config-custom-dropdown .ant-select-arrow {
    margin-right: 10px;
  }
}

/* Custom styles for the checkbox */
.rinex-custom-checkbox {
  appearance: none;
  background-color: black;
  border: 1px solid white;
  margin-top: 1px;
  position: relative;
  cursor: pointer;
}

/* Hide the default checkbox */
.rinex-custom-checkbox::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background-color: transparent;
}

/* Styling for the checked state */
.rinex-custom-checkbox:checked::before {
  content: "\2713"; /* Unicode checkmark character */
  font-size: 12px;
  line-height: 14px;
  color: white;
  text-align: center;
}

.rinex-download-button {
  border-radius: 12.606px;
  border: 1.261px dashed rgba(255, 255, 255, 0.07);
  background: rgba(52, 52, 52, 0.22);
}

.rinex-download-list {
  border-radius: 7px;
  background: rgba(77, 77, 77, 0.18);
}

.rinex-delete-list {
  border-radius: 7px;
  background: rgba(252, 45, 45, 0.09);
}

/* wallet */

.wallet-transection-section {
  border-radius: 18.656px;
  border: 0.491px solid #282828;
  background: rgba(32, 32, 32, 0.52);
}

.wallet-transection-table thead tr {
  color: rgba(255, 255, 255, 0.39);
  text-align: left;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  white-space: nowrap;
  background-color: transparent;
}

.wallet-transection-table thead tr td {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.wallet-transection-table tbody tr td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.wallet-transection-table tbody tr:nth-child(odd) td {
  background: rgba(52, 52, 52, 0.22);
}

.wallet-transection-table tbody tr:nth-child(odd) td:first-child {
  border-radius: 7px 0 0 7px;
}

.wallet-transection-table tbody tr:nth-child(odd) td:last-child {
  border-radius: 0 7px 7px 0;
}

.table-container {
  overflow-x: auto;
}

.wallet-average-mining {
  border-radius: 7px;
  background: #252525;
}

.wallet-average-mining-button {
  border-radius: 5px;
  background: transparent;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  width: 30px;
  height: 30px;
  font-size: 10px;
  cursor: pointer;
}

.wallet-average-mining-button:hover {
  border-radius: 5px;
  background: var(--green, linear-gradient(133deg, #4afc2d 0%, #1ea707 100%));
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  color: rgba(27, 27, 27, 1);
}

.wallet-average-mining-button:focus {
  border-radius: 5px;
  background: var(--green, linear-gradient(133deg, #4afc2d 0%, #1ea707 100%));
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  color: rgba(27, 27, 27, 1);
}

.wallet-sidebar-mini-cards {
  border-radius: 7px;
  background: #252525;
  padding: 12px 15px 10px 15px;
}

.wallet-sidebar-exchange {
  border-radius: 18.721px;
  background: rgba(7, 100, 167, 0.18);
}

.wallet-custom-dropdown {
  width: 100%;
  border-radius: 15px;
  background: rgba(37, 37, 37, 0.4);
}

.wallet-custom-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
  background-color: transparent;
  border: 1px solid transparent;
  color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  font-size: 14px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: transparent !important;
  box-shadow: 0 0 0 2px transparent !important;
}

.wallet-custom-dropdown .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: rgba(255, 255, 255, 1);
}

.wallet-custom-dropdown .ant-select-arrow {
  color: rgba(255, 255, 255, 1);
  margin-right: 20px;
}

.wallet-custom-input {
  font-size: 15px;
  color: white;
  background: rgba(37, 37, 37, 0.4);
  border-radius: 15px;
  border: 1px solid transparent;
  padding: 14px 20px;
}

.wallet-custom-input:hover {
  border-color: white !important;
  box-shadow: 0 0 0 1px white !important;
}

.wallet-custom-input:focus {
  border-color: #1ea707 !important;
  box-shadow: 0 0 0 1px #1ea707 !important;
}

.wallet-custom-input:active {
  border-color: #1ea707 !important;
}

.wallet-custom-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
}

.deposit-steps {
  border-radius: 18.656px;
  border: 0.491px solid #282828;
  background: rgba(32, 32, 32, 0.52);
}

.credits-section {
  border-radius: 18.656px;
  border: 0.491px solid #282828;
  background: rgba(32, 32, 32, 0.52);
}

.purchase-amount {
  border-radius: 15px;
  background: #252525;
}

.purchase-custom-input {
  background-color: #252525;
  border-color: #252525;
  color: white;
  font-size: 14px;
}

.purchase-custom-input::placeholder {
  color: rgba(99, 99, 99, 0.63);
  font-size: 14px;
}

.purchase-custom-input:hover {
  border-color: #252525;
  box-shadow: 0 0 0 1px #252525 !important;
}

.purchase-custom-input:focus {
  border-color: #252525;
  box-shadow: 0 0 0 2px #252525 !important;
}

.purchase-bottom-card {
  border-radius: 18.656px;
  border: 0.491px solid #282828;
  background: rgba(32, 32, 32, 0.52);
}

.purchase-warning {
  border-radius: 15px;
  background: rgba(7, 100, 167, 0.18);
}

.credits-overview {
  border-radius: 18.656px;
  border: 0.491px solid #282828;
  background: rgba(32, 32, 32, 0.52);
}

.credits-overview-table tbody tr td {
  padding-top: 7px;
  padding-bottom: 7px;
}

.credits-tier-section {
  border-radius: 18.656px;
  border: 0.491px solid #282828;
  background: rgba(32, 32, 32, 0.52);
}

.redeem-action {
  color: rgba(207, 99, 0, 1);
}

.redeem-action-disabled {
  color: rgba(255, 255, 255, 0.43);
}

.stakes-warning {
  border-radius: 15px;
  background: rgba(37, 37, 37, 0.46);
}

.stake-days {
  border-radius: 9px;
  background: rgba(78, 78, 78, 0.38);
  padding: 8px 14px;
}

/* Default styles for the switch */
.configure-custom-switch .ant-switch {
  color: #000000 !important;
  background-color: #000000 !important;
  border-radius: 6px;
}

/* Styles for the switch when it's in the "on" state */
.configure-custom-switch.switch-on .ant-switch-handle::before {
  border-radius: 4px;
  background: var(--green, linear-gradient(133deg, #4afc2d 0%, #1ea707 100%));
}

/* Styles for the switch when it's in the "off" state */
.configure-custom-switch.switch-off .ant-switch-handle::before {
  border-radius: 4px;
  background: var(--red, linear-gradient(133deg, #ff0000 0%, #cc0000 100%));
}

.wallet-credits-section {
  border-radius: 18.721px;
  border: 0.852px solid rgba(78, 78, 78, 0.36);
  background: rgba(32, 32, 32, 0.07);
}

@media screen and (max-width: 767px) {
  .wallet-navbar {
    border-radius: 15px;
    background: rgba(37, 37, 37, 0.4);
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .wallet-transection-section {
    border-radius: 7px;
    border: 0.5px solid #454545;
    background: #202020;
  }
  .wallet-custom-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
    font-size: 13px;
  }
  .wallet-custom-dropdown .ant-select-arrow {
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) and (min-width: 426px) {
  .main-wallet-custom-vh {
    max-height: calc(100vh - 330px) !important;
  }
  .deposit-steps {
    border-radius: 18.656px;
    border: 0.491px solid transparent;
    background: transparent;
  }
  .credits-section {
    border-radius: 18.656px;
    border: none;
    background: transparent;
  }
  .credits-tier-section {
    border-radius: 7px;
    border: 0.5px solid #454545;
    background: #1b1b1b;
  }
  .sidebar-mobile-rinex {
    border: 0.852px solid rgba(78, 78, 78, 0.36);
    background: rgba(32, 32, 32, 0.91);
    backdrop-filter: blur(5px);
  }
  .wallet-credits-section {
    border-radius: none;
    border: none;
    background: transparent;
  }
  .configure-select-size {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .rinex-custom-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
    color: white;
    border-radius: 8px;
    font-size: 9px;
  }

  .hotspot-config-custom-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
    background-color: transparent;
    border: 1px solid transparent;
    color: white;
    border-radius: 8px;
    font-size: 9px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
  }
}

@media screen and (max-width: 425px) {
  .main-wallet-custom-vh {
    max-height: calc(100vh - 330px) !important;
  }
  .deposit-steps {
    border-radius: 18.656px;
    border: 0.491px solid transparent;
    background: transparent;
  }

  .credits-section {
    border-radius: 18.656px;
    border: none;
    background: transparent;
  }
  .sidebar-mobile-rinex {
    border: 0.852px solid rgba(78, 78, 78, 0.36);
    background: rgba(32, 32, 32, 0.91);
    backdrop-filter: blur(5px);
  }
  .wallet-credits-section {
    border-radius: none;
    border: none;
    background: transparent;
  }
  .configure-select-size {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .rinex-custom-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
    color: white;
    border-radius: 8px;
    font-size: 9px;
  }

  .hotspot-config-custom-dropdown .ant-select:not(.ant-select-disabled) .ant-select-selector {
    background-color: transparent;
    border: 1px solid transparent;
    color: white;
    border-radius: 8px;
    font-size: 9px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
  }
}

@media screen and (min-width: 1701px) {
  .main-login-btn-lg {
    padding-left: 50px;
    padding-right: 50px;
  }
  .main-signup-btn-lg {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 1740px) and (min-width: 1535px) {
  .setting-left-border {
    border-left: 1px solid transparent;
  }
}

@media screen and (max-width: 1279px) and (min-width: 1024px) {
  .setting-left-border {
    border-left: 1px solid transparent;
  }
  .map-search {
    width: 300px;
    height: 55px;
  }
}

@media screen and (max-width: 1024px) {
  .setting-left-border {
    border-left: 1px solid transparent;
  }
  .map-search {
    width: 100%;
    height: 55px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 769px) {
  .sidebar-custom-vh {
    min-height: calc(100vh - 143px) !important;
  }
  .skyplot-checbox-width {
    width: 20%;
  }
}

@media screen and (min-width: 1700px) {
  .skyplot-checbox-width {
    width: 20%;
  }
}

/* main section */

.main-hotspot-show-div {
  border-radius: 7px;
  background: linear-gradient(130deg, rgba(45, 45, 45, 0.22) 8.47%, #292929 100%);
}

.main-hotspot-login-div {
  border-radius: 7px;
  border: 0.5px solid #282828;
  background: #202020;
}

.main-mining-performance {
  border-radius: 7px;
  background: #202020;
}

.sidebar-graph-div {
  border-radius: 19px;
  border: 0.5px solid #282828;
  background: #202020;
}

.sidebar-earnings {
  border-radius: 7px;
  background: linear-gradient(130deg, rgba(45, 45, 45, 0.22) 8.47%, #292929 100%);
}

.rtcm-messsage {
  border-radius: 7px;
  background: linear-gradient(92deg, rgba(45, 45, 45, 0.22) 26.76%, #292929 148.03%);
}

.leaflet-popup-content-wrapper {
  /* color: #333;
  border: 1px solid #404040 !important;
    box-shadow: 0 3px 14px rgba(0,0,0,0.4); */
  width: 250px !important;
}

.leaflet-popup-content {
  width: 250px !important;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  border-radius: 11px !important;
  border: 1px solid #404040 !important;
  background: rgba(9, 9, 9, 0.68) !important;
  backdrop-filter: blur(3.5px) !important;
  width: 260px !important;
  /* height: 120px !important; */
  padding: 0 5px 5px 5px !important;
}

.leaflet-popup-content p {
  margin: 12px 0px 18px 0px !important;
  width: 260px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.create-account-btn {
  border-radius: 11px;
  border: 0.5px solid var(--Theme-Color, #4afc2d);
  background: rgba(24, 24, 24, 0.13);
  /* width: 188px; */
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.leaflet-popup-content {
  margin: 0 !important;
}

.view-base-btn {
  border-radius: 11px;
  border: 0.5px solid rgba(24, 24, 24, 0.13);
  background: var(--Theme-Color, linear-gradient(94deg, #4afc2d 8.71%, #1ea707 100%));
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  /* width: 92px; */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.use-rtk-btn {
  border-radius: 11px;
  border: 0.5px solid var(--Theme-Color, #4afc2d);
  background: rgba(24, 24, 24, 0.13);
  /* width: 92px; */
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.active-hotspot-wrapper {
  width: 38px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #fff 100%);
}

.layer-hotspot-wrapper {
  width: 200px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, rgba(107, 21, 175, 0.8) 19.79%, rgba(244, 246, 101, 0) 100%);
}
/* charts */

.radar-chart-background {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.radar-chart-background:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: repeating-radial-gradient(circle, transparent, transparent 2px, #333 2px, #333 4px);
  opacity: 0.3;
}

.apexcharts-menu-icon,
.apexcharts-reset-icon,
.apexcharts-zoom-icon {
  display: none;
}

/* map */

:root {
  --leaflet-tile-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

.leaflet-tile {
  filter: var(--leaflet-tile-filter, none) !important;
}

.leaflet-container {
  background: #303030 !important;
}

/* testing */

.contract-height {
  height: 140px;
  transition: height 0.5s ease;
}

.expand-height {
  height: calc(100vh - (163px + 65px));
  transition: height 0.5s ease;
}

.mobile-sidebar-bg {
  border: 0.852px solid rgba(78, 78, 78, 0.36);
  background: rgba(32, 32, 32, 0.91);
  backdrop-filter: blur(5px);
}

.modal-container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

/* button */

.bg-hover {
  background: var(--Green, linear-gradient(94deg, #4afc2d 8.71%, #1ea707 100%));
  border: 1px solid transparent;
}

.bg-25 {
  background: #252525;
}

/* .bg-25 {
  background: #252525;
} */

.bg-hover:hover {
  background: rgba(32, 32, 32, 0.63);
  color: white;
  border: 1px solid #4afc2d;
}

.bg-disable {
  background: rgba(255, 255, 255, 0.43);
  border: 1px solid transparent;
}

/* step modal */

.hotspot-step-modal
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #ffffff;
}
.hotspot-step-modal .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(255, 255, 255, 0.7);
}

.hotspot-step-modal
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(255, 255, 255, 0.7);
}

.hotspot-step-modal .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: rgb(74, 252, 45);
}

.hotspot-step-modal .ant-steps-item-wait .ant-steps-item-icon {
  background-color: rgba(255, 255, 255, 0.5);
}

.hotspot-step-modal .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #000000;
}

.hotspot-step-modal .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #000000;
}

.hotspot-step-modal .ant-steps-item-process .ant-steps-item-icon {
  border-color: rgb(74, 252, 45) !important;
}

.hotspot-step-modal .ant-steps-item-wait .ant-steps-item-icon {
  border-color: transparent !important;
}
.hotspot-step-modal .ant-steps-item-title {
  color: #fff !important;
}
.hotspot-step-modal .ant-steps-item-finish .ant-steps-item-icon {
  border-color: rgb(74, 252, 45) !important;
  background-color: rgb(74, 252, 45) !important;
}
.hotspot-step-modal .ant-steps-icon > span svg {
  fill: black;
}
.hotspot-step-modal .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-tail::after {
  background-color: rgb(74, 252, 45) !important;
}

.ntrip-alert .ant-alert-message {
  color: white;
}

.skyplot-graph .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #4e94fa !important;
  color: #4e94fa !important;
}

.skyplot-graph .ant-checkbox-inner {
  width: 13px !important;
  height: 13px;
}

.skyplot-graph .ant-checkbox-checked .ant-checkbox-inner::after {
  font-size: 5px !important; /* You can adjust the size as needed */
}

.skyplot-graph .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 1px solid #4e94fa;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}

.satellite .apexcharts-legend {
  justify-content: space-around !important;
}

.satellite .apexcharts-legend .apexcharts-legend-series > span:nth-child(1) {
  margin-right: 13px !important;
}

.dop-chart .apexcharts-legend {
  justify-content: space-around !important;
}

.dop-chart .apexcharts-legend .apexcharts-legend-series > span:nth-child(1) {
  margin-right: 13px !important;
}

/* .apexcharts-text .apexcharts-xaxis-label {
  color: white !important;
  fill: white !important;
} */

/* .skyplot-graph-from-baseview; */

.skyplot-graph-from-baseview .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #4e94fa !important;
  color: #4e94fa !important;
}

/* .skyplot-graph-from-baseview .ant-checkbox-inner {
  width: 12px !important;
  height: 12px;
} */

/* .skyplot-graph-from-baseview .ant-checkbox-checked .ant-checkbox-inner::after {
  font-size: 5px !important; 
} */

.skyplot-graph-from-baseview .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 1px solid #4e94fa;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}

.ant-select-item-option-active:not(.ant-select-item-option) {
  background-color: transparent !important;
  color: white !important;
  /* padding: 8px 14px !important; */
  z-index: 999;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
  color: white !important;
  /* padding: 8px 14px !important; */
  z-index: 999;
}

.ant-select-dropdown {
  background-color: rgba(37, 37, 37, 1) !important;
  border-radius: 7px !important;
  color: white !important;
  padding: 12px 14px !important;
}

.ant-select-item-option-content {
  color: white !important;
  background-color: transparent !important;
  color: white !important;
  padding: 6px 0px;
}

.leaflet-interactive {
  position: relative;
  z-index: 1000;
}
.leaflet-pane svg g {
  position: relative;
  z-index: 1000000000000;
}
.leaflet-marker-pane {
  position: relative;
  z-index: 354 !important;
}
